*{
    box-sizing: border-box;
}
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    font-size: 16px;
    color: white;
}
#body {
    position: absolute;
    height: 83vh;
    margin: 0;
    padding: 0;
    top: 17vh;
    width: 100%;
}
#heart {
    width: 100%;
    height: 100%;
}
.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* standard-bank-1155x770-1.png */
    background-image: url('../imgs/SBSA BACKGROUND.jpg');
    background-size: cover;
    background-position: center;
    z-index: -1;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(1, 1, 73, 0.9); 
    /* background-color: #090087; */
    z-index: -1;
}

.overlay-light {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(1, 1, 73, 0.7);  */
    z-index: -1;
}

.flexing {
    display: flex;
    /* border: 1px solid white; */
    /* height: 100%; */
}
.center_v_and_h {
    /* flex-direction: row; */
    align-items: center;
    justify-content: center;
}

.btn {
    display: inline-block;
    cursor: pointer;
    /* border: 1px solid white; */
    width: auto;
    height: auto;
    margin: 0;
}

.circles_cont {
    justify-content: space-around;
    height: 100%;
    width: 85%;
    margin: auto;
    /* border: 1px solid red; */
}
.section_name_cont {
    background: linear-gradient(to right, rgba(0, 0, 139, 0.8), rgba(0, 0, 139, 0.8), rgba(100, 120, 200, 0.8));
    width: 100%;
    margin: auto;
    margin-top: 20px;
    padding: 15px;
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: center;
    font-weight: bold;
    border-radius: 3px;
}

.shield-label {
    /* border: 1px solid white; */
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    margin-top: -20px;
}